<template>
  <n-el>
    <b>Mokymosi progresas:</b>
    <n-progress
      type="line"
      :color="'var(--primary-color)'"
      :percentage="(stats.fullyFinishedLessons / stats.totalLessons) * 100"
      processing
      >
        Temos: {{ stats.fullyFinishedLessons}} iš {{ stats.totalLessons }}
    </n-progress>
    <n-progress
      type="line"
      :color="'var(--primary-color)'"
      :percentage="(stats.finishedLessonChilds / stats.totalLessonChilds) * 100"
      processing
      >
        Potemės: {{ stats.finishedLessonChilds }} iš {{ stats.totalLessonChilds }}
    </n-progress>
    <br>
  </n-el>
</template>

<script>

import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { getMenuWithFinishedLessons, getStats } from '@/utils/sertificatePDF'

import { useThemeVars } from 'naive-ui'
// import {  } from '@vicons/ionicons5'

export default defineComponent({
  components: {
  },
  setup () {
    const store = useStore()

    const stats = computed(() => {
      let userData = store.getters['userData/getUserData']
      let menu = store.getters['menu/getMenu']
      const menu_ = getMenuWithFinishedLessons(userData, menu)

      const stats = getStats(menu_)
      return stats
    })

    return {
      stats,
      themeVars: useThemeVars()
    }
  }
})
</script>

<style scoped>
</style>
