<template :style="noColors ? 'webkit-filter: grayscale(1) !important; filter: grayscale(1) !important;' : ''">
  <div bordered class="nav" :style="style">
    <n-text tag="div" class="ui-logo" :depth="1" @click="goto">
      <!-- <img src="https://vpt.lrv.lt/uploads/vpt/documents/images/logo_small.png" /> -->
      <img class="zoom" src="@/assets/logo.png" width="500"/>
      <span v-if="!isMobile"><b>VPT E-vedlys</b></span>
    </n-text>
    <div>
      <div class="nav-menu" v-if="!(isMobile || isTablet)" :style="'align-items: right;'">
      </div>
    </div>
    <n-space horizontal>

      <!-- <n-select
        v-if="!(isMobile || isTablet)"
        filterable
        placeholder="paieška"
        :options="refactorMenu(store.getters['menu/getMenu'])"
        @update:value="handleSearchClick"
        :style="'max-width: 13em'"
      /> -->


      <n-popover
        placement="bottom-end"
        display-directive="show"
        trigger="click"
      >
        <template #trigger>
          <n-button>
            <template #icon>
              <n-icon><settings-outline/></n-icon>
            </template>
          </n-button>
        </template>
        <div>
          <n-space vertical>
          <n-h3 prefix="bar" type="primary">Nustatymai</n-h3>
          <n-button-group vertical>
            <n-button @click="changeTheme" ghost>
              <template #icon>
                <n-icon>
                  <sunny-outline v-if="isThemeDark()"/>
                  <moon-outline v-else/>
                </n-icon>
              </template>
              Šviesi / tamsi tema
            </n-button>
            <n-button @click="removeColors">
              <template #icon>
                <n-icon><color-palette-outline/></n-icon>
              </template>
              Įjungti / išjungti spalvas
            </n-button>
          </n-button-group>
          <n-button-group horizontal>
            <n-button @click="zoom(true)" ghost>
              Aa +
            </n-button>
            <n-button @click="zoom(false)">
              Aa -
            </n-button>
          </n-button-group>
        </n-space>
        </div>
      </n-popover>

      <n-popover
        placement="bottom-end"
        display-directive="show"
        trigger="click"
      >
        <template #trigger>
          <n-button>
            <template #icon>
              <n-icon><person-outline/></n-icon>
            </template>
          </n-button>
        </template>
        <div>
          <n-space vertical>
            <n-h3 prefix="bar" type="primary">Sveiki, {{ store.getters['auth/getAuthData'].userName }}</n-h3>
            <userProgress />
            <n-button-group vertical>
              <n-button v-if="store.getters['auth/getAuthData'].role == 'Admin'" @click="router.push('/statsMain')">Administratoriaus paskyra</n-button>
              <n-button @click="generatePDF_(store)"><template #icon><n-icon><ribbon-outline/></n-icon></template>Atsisiųsti sertifikatą</n-button>
              <n-button type="success" @click="store.dispatch('auth/logout') && window.location.reload(true)">
                <template #icon><n-icon><power-outline /></n-icon></template>
                Atsijungti
              </n-button>

            </n-button-group>
            <!-- <pre style="overflow-x:scroll;">{{ store.getters['auth/getAuthData'] }}</pre> -->
          </n-space>
        </div>
      </n-popover>

      <n-popover
        v-if="isMobile || isTablet"
        style="width: 350px"
        placement="bottom-end"
        display-directive="show"
        trigger="click"
        ref="mobilePopoverRef"
      >
        <template #trigger>
          <n-icon size="30"><menu-outline /></n-icon>
        </template>
        <div style="overflow: auto; max-height: 79vh">
          <Menu @menuClick="handleUpdateMobileMenu" />
        </div>
      </n-popover>
    </n-space>
  </div>
</template>

<script>

import { computed, defineComponent, ref } from 'vue'
import { useIsMobile, useIsTablet } from '@/utils/composables'
import { useStore } from 'vuex'
import Menu from '@/components/Menu.vue'
import { useRouter } from 'vue-router'
import { generatePDF } from '@/utils/sertificatePDF'
import userProgress from '@/components/userProgress.vue'

import {
  MenuOutline, SunnyOutline, MoonOutline, PersonOutline,
  PowerOutline, ColorPaletteOutline, SettingsOutline, RibbonOutline
} from '@vicons/ionicons5'

const isMobileRef = useIsMobile()
const isTabletRef = useIsTablet()

const generatePDF_ = generatePDF()

export default defineComponent({
  components: {
    Menu,
    userProgress,
    MenuOutline,
    SunnyOutline,
    MoonOutline,
    PersonOutline,
    PowerOutline,
    ColorPaletteOutline,
    SettingsOutline,
    RibbonOutline,
  },
  setup () {

    const store = useStore()
    const router = useRouter()
    const mobilePopoverRef = ref(null)

    function handleUpdateMobileMenu () {
      mobilePopoverRef.value.setShow(false)
    }

    // restructure menu object into an array with all possible
    // search items and keys to these items
    function refactorMenu(defaultMenu) {
      // console.log(defaultMenu)
      function extractMenuData(currentData, object) {
        let data = currentData;
        if (object.label) {
          data = data.length > 0 ? data + ': ' + object.label : data + object.label
          if (!object.children || (object.children && object.children.length == 0)) {
            data = data + ' /// ' + object.key
          }
        }
          if (object.children && object.children.length > 0) {
          data = object.children.map(item => extractMenuData(data, item));
        }
        return data;
      }
      const result = defaultMenu.map(arrayItem => extractMenuData('', arrayItem)).flat()
      const result_ = result.map(item => ({label: item.split(' /// ')[0], value: item.split(' /// ')[1]}))
      // console.log(result_)
      return result_
    }

    function handleSearchClick (value) {
      // console.log(value)
      store.commit('menu/setCurrentMenuSelection', value)
    }

    return {
      style: computed(() => {
        return isMobileRef.value
          ? {
              '--side-padding': '16px',
              'padding': '16px',
              'grid-template-columns': 'auto 1fr auto'
            }
          : {
              '--side-padding': '16px',
              'padding': '16px',
              'grid-template-columns':
                'calc(272px - var(--side-padding)) 1fr auto'
            }
      }),
      isMobile: isMobileRef,
      isTablet: isTabletRef,
      handleUpdateMobileMenu,
      mobilePopoverRef,
      store,
      router,
      handleSearchClick,
      refactorMenu,
      generatePDF_,
    }
  },
  methods: {
    changeTheme() {
      // console.log('dispaching store commit')
      this.$store.commit('theme/changeTheme')
      // console.log(this.$store.state.theme)
    },
    removeColors() {
      // console.log('dispaching store commit')
      this.$store.commit('theme/removeColors')
      // console.log(this.$store.state.theme)
    },
    isThemeDark() {
      return this.$store.state.theme.themeName == 'dark' ? true : false
    },
    goto() {
      this.$router.push('/docs')
      this.$store.commit('menu/setCurrentMenuSelection', '0')
    },
    zoom(inOrOut) {
      const bodyZoom = document.body.style.zoom
      const zoom = bodyZoom ? parseInt(bodyZoom.slice(0, -1)) : bodyZoom
      const increment = inOrOut ? 5 : -5

      if (zoom) {

        // if going to zoom out past 100 prevent this
        if (zoom + increment < 100) {
          return false
        }
        document.body.style.zoom = (zoom + increment).toString() + '%'
      } else {
        document.body.style.zoom = (100 + increment).toString() + '%'
      }
    }
  }
})
</script>

<style scoped>
.nav {
  display: grid;
  grid-template-rows: calc(var(--header-height) - 1px);
  align-items: center;
  padding: 0 var(--side-padding);
}
.ui-logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
}
.ui-logo > img {
  margin-right: 12px;
  height: 32px;
  width: 52px;
}
.nav-menu {
  padding-left: 92px;
}
.nav-picker {
  margin-right: 24px;
}
.nav-picker:last-child {
  margin-right: 0;
}
.nav-end {
  display: flex;
  align-items: center;
}

.zoom {
  transition: transform .5s; /* Animation */
}

.zoom:hover {
  transform: scale(1.2);
}


</style>
