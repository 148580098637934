// import { useStore } from 'vuex'
import { jsPDF } from "jspdf"
import autoTable from 'jspdf-autotable' // using npm
import { PTSans } from '@/utils/font.js'

// take these out so that it can be reused in user progress bar
export const getMenuWithFinishedLessons = (userData, menu) => {

  // 0. get the array of paths to lessons that are finished
  const lessonPaths = Object.keys(userData.lessonData)
  // couble chec if all are marked isFinished
  const finishedLessons = lessonPaths.filter(path => userData.lessonData[path].isFinished)
  // console.log(lessonPaths, finishedLessons)

  // 1. for each menu item, check if isFinished in userData
  const updateMenuItems = (array, arrayOfPathsOfFinishedLessons) => {
    const find = (item) => {
      if (arrayOfPathsOfFinishedLessons.includes(item.path)) {
        item.isFinished = true
      } else {
        delete item.isFinished
      }
      if (item.children && item.children.length > 0) {
        item.children.forEach(child => { find(child) })
      }
      return item
    }
    return array.map(find)
  }
  const updatedMenu = updateMenuItems(menu, finishedLessons)
  // console.log(updatedMenu)

  // 2. if all children are finsihed, mark parent as finished
  const updateMenuParents = (array) => {
    const find = (item) => {
      if (item.children && item.children.length > 0) {
        if (item.children.every((child) => child.isFinished)) {
          item.isFinished = true
        }
      }
      return item
    }
    return array.map(find)
  }
  const updatedMenu_ = updateMenuParents(updatedMenu)
  // console.log(updatedMenu_)

  return updatedMenu_
}

export const getStats = (updatedMenu_) => {

  // moduliai
  const totalLessons = updatedMenu_.length
  const fullyFinishedLessons = updatedMenu_.filter(item => item.isFinished).length

  // temos
  let totalLessonChilds = 0
  let finishedLessonChilds = 0
  updatedMenu_.forEach(item => {
    if (item.children && item.children.length > 0) {
      item.children.forEach(child => {
        totalLessonChilds += 1
        finishedLessonChilds += child.isFinished ? 1 : 0
      })
    }
  })

  const stats = {
    'totalLessons': totalLessons,
    'fullyFinishedLessons': fullyFinishedLessons,
    'totalLessonChilds': totalLessonChilds,
    'finishedLessonChilds': finishedLessonChilds,
  }

  return stats

}



export const generatePDF = function () {
  return (store) => {

    let userData = store.getters['userData/getUserData']
    let menu = store.getters['menu/getMenu']
    const user = store.getters['auth/getAuthData']

    const updatedMenu_ = getMenuWithFinishedLessons(userData, menu)

    // 3. Refactor to table
    var col = ['Tema', 'Pabaigtos temos pažymėtos +'];
    var rows = [];

    updatedMenu_.forEach(item => {
      rows.push([item.label, item.isFinished ? '+' : ''])
      // turn off logging potemes
      // if (item.children && item.children.length > 0) {
      //   item.children.forEach(child => {
      //     rows.push(['        ' + child.label, child.isFinished ? '+' : ''])
      //   })
      // }
    })

    // get all stats
    const stats = getStats(updatedMenu_)

    // 4. make the pdf
    var doc = new jsPDF()
    doc.addFileToVFS("PTSans.ttf", PTSans)
    doc.addFont('PTSans.ttf', 'PTSans', 'normal')
    doc.setFont("PTSans");

    var width = doc.internal.pageSize.getWidth()

    var img = new Image()
    img.src = require('@/assets/logo-full.png')
    doc.addImage(img, 'png', width/2-22, 20, 50, 43)

    doc.setFontSize(22)
    doc.text('E-Vedlys mokymų sertifikatas', width/2, 100, { align: 'center' })

    doc.setFontSize(15)
    doc.text(user.userName + ' ' + user.userLastName, width/2, 120, { align: 'center' })
    doc.setFontSize(10)
    doc.text(new Date().toISOString().split('T')[0], width/2, 130, { align: 'center' })

    doc.setFontSize(15)
    doc.text('Mokymų progresas', width/2, 150, { align: 'center' })

    doc.setFontSize(10)
    const progress1 = 'Pilnai pabaigtos temos:'
    doc.text(progress1, width/2, 160, { align: 'center' })

    doc.setFontSize(15)
    doc.setTextColor(0,106,68)
    // doc.setFont('PTSans', 'bold')
    const progress1_stat = String(stats.fullyFinishedLessons) + ' iš ' + String(stats.totalLessons)
    doc.text(progress1_stat, width/2, 167, { align: 'center' })

    // const progress2 = 'Pabaigtos potemės: ' + String(stats.finishedLessonChilds) + ' iš ' + String(stats.totalLessonChilds)
    // doc.text(progress2, width/2, 165, { align: 'center' })

    doc.setFontSize(10)
    doc.setTextColor(0,0,0)
    // doc.setFont(undefined, 'normal')
    doc.text('Bendras pabaigtumo lygis:', width/2, 175, { align: 'center' })

    doc.setFontSize(15)
    doc.setTextColor(0,106,68)
    // doc.setFont('PTSans', 'bold')
    const progress_pct = String(Math.round(stats.fullyFinishedLessons/stats.totalLessons * 100)) +'%'
    doc.text(progress_pct, width/2, 182, { align: 'center' })

    // png elementas
    var img2 = new Image()
    img2.src = require('@/assets/logo.png')
    doc.addImage(img, 'png', width-50, 250, 100, 86)

    autoTable(
      doc,
      {
        head: [col],
        body: rows,
        styles: {font: "PTSans"},
        headStyles: { fillColor: '#006a44' },
        startY: 300,
        columnStyles: { 0: { halign: 'left' }, 1: { halign: 'right' } },

        // this is purley to allign 2nd header col
        didParseCell: (hookData) => {
          if (hookData.section === 'head') {
            if (hookData.column.dataKey === 1) {
            console.log(hookData)
              hookData.cell.styles.halign = 'right';
            }
          }
        }

      }
    );
    doc.save('sertifikatas.pdf')
  }
}
